<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<fuse-sidebar name="AppointmentDetails" class="appointment-details-sidebar" position="right" [invisibleOverlay]="true" (openedChanged)="AppointmentDetailsOpen($event)">
    <appointment-details-sidebar style="height: 100%;"></appointment-details-sidebar>
</fuse-sidebar>

<fuse-sidebar name="FileDetails" class="file-details-sidebar" position="right" [invisibleOverlay]="false" (openedChanged)="FileDetailsOpen($event)">
    <file-details-sidebar style="height: 100%;"></file-details-sidebar>
</fuse-sidebar>

<fuse-sidebar name="ProductImportDetails" class="product-details-sidebar" position="right" [invisibleOverlay]="false" (openedChanged)="ProductImportDetailsOpen($event)">
    <product-details-sidebar style="height: 100%;"></product-details-sidebar>
</fuse-sidebar>

<!-- / THEME OPTIONS PANEL -->
<notifier-container></notifier-container>

<ngx-spinner name="fullpage" type="ball-clip-rotate-multiple" size="medium"></ngx-spinner>