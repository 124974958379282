import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from "./core/core.module";
import 'hammerjs';
import { CookieModule, CookieService } from 'ngx-cookie';
import { NotifierModule } from 'angular-notifier';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { AppointmentDetailsSidebarModule } from 'app/main/components/appointment-details-sidebar/appointment-details-sidebar.module';
import { FileDetailsSidebarModule } from 'app/main/components/file-details-sidebar/file-details-sidebar.module';
import { ProductDetailsSidebarModule } from 'app/main/components/product-details-sidebar/product-details-sidebar.module';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { Routing } from "./app.routing";

import { AuthenticationService } from 'app/core/services/authentication.service';
import { AuthInterceptor } from 'app/core/interceptors/auth.interceptor';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { PipesModule } from 'app/pipes/pipes.module';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
      AppComponent
  ],
  imports     : [
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      Routing,

      TranslateModule.forRoot(),

      // Material moment date module
      MatMomentDateModule,

      // Material
      MatButtonModule,
      MatIconModule,

      // Fuse modules
      FuseModule.forRoot(fuseConfig),
      FuseProgressBarModule,
      FuseSharedModule,
      FuseSidebarModule,
      FuseThemeOptionsModule,
      AppointmentDetailsSidebarModule,
      FileDetailsSidebarModule,
      ProductDetailsSidebarModule,

      // App modules
      LayoutModule,
      AppStoreModule,
      NgxSpinnerModule,
      CoreModule,
      CookieModule.forRoot(),
      NgxMaskModule.forRoot(maskConfig),
      NotifierModule.withConfig({
        position: {
          horizontal: {
            position: 'right',
          },
          vertical: {
            position: 'top',
          }
        }
      }),
      LeafletModule,
      PipesModule
  ],
  bootstrap   : [
      AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
})
export class AppModule
{
}
