import { RouterModule, Routes } from '@angular/router';

const appRoutes: Routes = [{ 
  path: 'home', 
  loadChildren: () => import('app/main/pages/home/home.module').then(mod => mod.HomeModule),
  data: { auth: [ 'guest' ] }
}, { 
  path: 'auth',
  loadChildren: () => import('app/main/pages/pages.module').then(mod => mod.PagesModule) 
}, { 
  path: 'management', 
  loadChildren: () => import('app/main/pages/management/management.module').then(mod => mod.ManagementModule) 
}, { 
  path: 'clients', 
  loadChildren: () => import('app/main/pages/clients/clients.module').then(mod => mod.ClientsModule)
}, { 
  path: 'offices', 
  loadChildren: () => import('app/main/pages/offices/offices.module').then(mod => mod.OfficesModule)
}, { 
  path: 'categories', 
  loadChildren: () => import('app/main/pages/categories/categories.module').then(mod => mod.CategoriesModule)
}, { 
  path: 'products', 
  loadChildren: () => import('app/main/pages/products/products.module').then(mod => mod.ProductsModule)
}, { 
  path: 'services', 
  loadChildren: () => import('app/main/pages/services/services.module').then(mod => mod.ServicesModule)
}, { 
  path: 'bannersoffice', 
  loadChildren: () => import('app/main/pages/banners-office/banners-office.module').then(mod => mod.BannersOfficeModule)
}, { 
  path: 'price-list', 
  loadChildren: () => import('app/main/pages/price-list/price-list.module').then(mod => mod.PriceListModule)
}, { 
  path: 'orders', 
  loadChildren: () => import('app/main/pages/orders/orders.module').then(mod => mod.OrdersModule)
}, { 
  path: 'calendar', 
  loadChildren: () => import('app/main/pages/calendar/calendar.module').then(mod => mod.CalendarModule)
}, { 
  path: 'appointments', 
  loadChildren: () => import('app/main/pages/appointments/appointments.module').then(mod => mod.AppointmentsModule)
}, { 
  path: 'coupons', 
  loadChildren: () => import('app/main/pages/coupons/coupons.module').then(mod => mod.CouponsModule)
}, { 
  path: 'settings', 
  loadChildren: () => import('app/main/pages/settings/settings.module').then(mod => mod.SettingsModule)
}, { 
  path: 'messages', 
  loadChildren: () => import('app/main/pages/messages/messages.module').then(mod => mod.MessagesModule)
}, { 
  path: '', 
  redirectTo: '/home', 
  pathMatch: 'full' 
}, { 
  path: 'unauthorized', 
  loadChildren: () => import('app/main/pages/errors/unauthorized/unauthorized.module').then(mod => mod.UnauthorizedModule),
  data: { auth: [ 'guest' ] }
}, { 
  path: '**', 
  redirectTo: '/home'
}];


export const Routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
